import React from 'react';
import {slide as Menu} from "react-burger-menu";
import {burgerMenuStyles} from "../../helpers/burger-menu-styles";
import './Sidebar.css';

const Sidebar = ({ allProducts, onMenuClick, handleSidebar, sideBarOpen }) => {
    return (
        <div>
            <Menu
                styles={burgerMenuStyles}
                customBurgerIcon={ false }
                isOpen={sideBarOpen}
                onClose={handleSidebar}
            >
                {allProducts.map(product => {
                    return <a key={product.cat_id} href={`#${product.cat_name}`} className="menu-item" onClick={() => onMenuClick(product.cat_name)}>
                        <img src={`../../assets/menu_icons/${product.cat_id}.svg`} alt=""/>
                        <p className={'item-text'}>{product.cat_name}</p>
                    </a>
                })}
                <div className={'burger__custom-btn'} onClick={handleSidebar}>
                    <img className={'arrow'} src={'../../assets/menu_icons/11.svg'}/>
                </div>
            </Menu>
        </div>
    );
};

export default Sidebar;
