import React from 'react';
import './Header.css';
import {useTelegram} from "../../hooks/useTelegram";
import Sidebar from "../Sidebar/Sidebar";

const Header = ({ allProducts, onMenuClick, toggleModal, addedItems, handleSidebar, sideBarOpen }) => {

    const { user } = useTelegram();

    return (
        <div className={'header'}>
            <img
                src="../../assets/burger-button.svg"
                alt="" className={'burger__top-left-btn'}
                onClick={handleSidebar}
            />
            <Sidebar
                allProducts={allProducts}
                onMenuClick={onMenuClick}
                handleSidebar={handleSidebar}
                sideBarOpen={sideBarOpen}
            />
            <span className={'username'}>
                Привіт, <span className={'usertext'}>{user?.username}</span>
            </span>
            <span
                onClick={toggleModal}
                className={'cart-btn'}
            >
                🛒
                {addedItems.length > 0 ? <span className={'cart-badge'}>{addedItems.reduce((c, cur) => c + cur.ordered, 0)}</span> : ""}
            </span>

        </div>
    )
}

export default Header;
