import React, {useEffect, useState} from 'react';
import './CartItem.css';
import Button from "../../Button/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CartItem = ({product, addedItems, onAdd, onRemove, className, handlePizzaClick}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        addedItems.forEach(e => {
            if (product.name === e.name) {
                setCount(e.ordered);
            }
        })
    }, [addedItems, product.name]);

    return (
        <div className={'cart-product ' + className}>
            <div className={'cart-img'}>
                {
                    product.name.includes("Піца") ?
                        <LazyLoadImage
                            src={product.foto_link}
                            alt={product.name}
                            onClick={() => handlePizzaClick(product)}
                            width={'auto'}
                        /> :
                        <LazyLoadImage
                            src={product.foto_link}
                            alt={product.name}
                            width={'94px'}
                        />
                }
            </div>
            {
                product.name.includes("Піца") ?
                    <div className={'title'} onClick={() => handlePizzaClick(product)}>
                        {product.name}
                        <br/>
                        <div className={'description'}>{product.description}</div>
                    </div>:
                    <div className={'title'}>
                        {product.name}
                        <br/>
                        <div className={'description'}>{product.description}</div>
                    </div>
            }
            <div className={'price'}>
                <span><b className={'price-tag'}>{product.price} <br/> грн.</b></span>
            </div>
            <div className={'button-container'}>
                <h1 className={'count'}>{count} шт.</h1>
                <Button title={'+'} disable={false} onClick={() => onAdd(product)} type={'add'}/>
                <Button title={'-'} disable={false} onClick={() => onRemove(product)} type={'remove'}/>
            </div>
        </div>
    );
};

export default CartItem;
