import './App.css';
import {useEffect, useState} from "react";
import Header from "./components/Header/Header";
import {useTelegram} from "./hooks/useTelegram";
import ProductList from "./components/ProductList/ProductList";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return '${day}${month}${year}';
}

function App() {
    const {tg} = useTelegram();
    const [catToRender, setCatToRender] = useState({})
    const [products, setProducts] = useState([])

    useEffect(() => {
        tg.ready();
    });

    useEffect(() => {
        async function fetchData() {
            return await fetch("./assets/demo_3181.json?ver=${formattedDate}", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
            })
                .then(res => res.json())
                .then((data) => {
                    setProducts(data)
                    setCatToRender(data[0])
                })
        }
        fetchData()
    }, [])

    const notifyAdded = () => toast.success("Додано!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const notifyDeleted = () => toast.error("Видалено!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });


    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [orderIsDone, setOrderIsDone] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [addedItems, setAddedItems] = useState([]);

    const handleSidebar = () => {
        sideBarOpen ? setSideBarOpen(false) : setSideBarOpen(true);
    }
    //open cart
    const toggleModal = () => {
        !modalIsOpen && addedItems.length > 0 ? setModalIsOpen(true) : setModalIsOpen(false);
    }
    //close cart
    const closeModal = () => {
        setModalIsOpen(false)
    }

    const onMenuClick = (category) => {
        let element = document.getElementsByClassName("bm-overlay")[0];
        element.click();
        const selectCat = products.filter(item => item.cat_name === category)
        setCatToRender(selectCat[0])

    }

    const onAdd = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        if (alreadyAdded) {
            setAddedItems(
                addedItems.map((e) => {
                    return e.id === product.id ? {...alreadyAdded, ordered: alreadyAdded.ordered + 1} : e;
                })
            )
        } else {
            setAddedItems([...addedItems, {...product, ordered: 1}])
        }
        notifyAdded();
    }

    const onRemove = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        if (alreadyAdded !== undefined && alreadyAdded.ordered === 1) {
            setAddedItems(
                addedItems.filter(e => e.id !== product.id)
            )
        } else {
            setAddedItems(addedItems.map(e => {
                return e.id === product.id ? {...alreadyAdded, ordered: alreadyAdded.ordered - 1} : e;
            }))
        }
        notifyDeleted();
    }

    return (
        <div className="App">
            <Header
                allProducts={products}
                onMenuClick={onMenuClick}
                toggleModal={toggleModal}
                addedItems={addedItems}
                handleSidebar={handleSidebar}
                sideBarOpen={sideBarOpen}
            />
            <ToastContainer style={{width: "160px", left: "inherit", top: "80px"}}/>
            <ProductList
                    catToRender={catToRender}
                    products={products}
                    addedItems={addedItems}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    modalIsOpen={modalIsOpen}
                    closeModal={closeModal}
                    setModalIsOpen={setModalIsOpen}
                    orderIsDone={orderIsDone}
                    setOrderIsDone={setOrderIsDone}
            />

        </div>
    );
}

export default App;
