import React, {useEffect, useState} from 'react';
import './ProductList.css'
import ProductItem from "./ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import Cart from "../Cart/Cart";
import Form from "../Form/Form";
import {isValidPhoneNumber} from "react-phone-number-input";
import PizzaModal from "../PizzaModal/PizzaModal";

const ProductList = ({
                         catToRender,
                         products,
                         addedItems,
                         onAdd,
                         onRemove,
                         setModalIsOpen,
                         modalIsOpen,
                         closeModal,
                         orderIsDone,
                         setOrderIsDone,
                     }) => {
    const {tg} = useTelegram();
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [building, setBuilding] = useState('');

    const [pizzaClicked, setPizzaClicked] = useState(false);
    const [productForPizzaModal, setProductForPizzaModal] = useState({})

    const handlePizzaClick = (pizza) => {
        tg.MainButton.hide();
        if (!pizzaClicked) {
            setProductForPizzaModal(pizza);
            setPizzaClicked(true);
        }
        if (pizzaClicked) {
            setProductForPizzaModal({});
            setPizzaClicked(false);
            setModalIsOpen(false);
            setOrderIsDone(false);
        }
    }

    const goToCart = () => {
        setModalIsOpen(true)
        if (pizzaClicked) {
            setPizzaClicked(false);
        }
    };

    const goToForm = () => setOrderIsDone(true);
    const goPay = () => {
        if (!isValidPhoneNumber(phone)) {
            alert("Перевірте номер телефону!")
        } else {
            alert("Pay or die!")
        }
    }

    useEffect(() => {
        if (addedItems.length > 0 && !modalIsOpen && !orderIsDone) {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: 'В кошик',
                color: "#ff8c00",
            })
            tg.onEvent('mainButtonClicked', goToCart);
            return () => {
                tg.offEvent('mainButtonClicked', goToCart);
            }
        } else if (addedItems.length > 0 && modalIsOpen && !orderIsDone && !pizzaClicked) {
            const totalPrice = addedItems.reduce((a, c) => a + c.price * c.ordered, 0)
            tg.MainButton.show();
            tg.MainButton.setText(
                `Замовити на ${totalPrice} грн`
            )
            tg.onEvent('mainButtonClicked', goToForm);
            return () => {
                tg.offEvent('mainButtonClicked', goToForm);
            }
        } else if (addedItems.length > 0 && modalIsOpen && orderIsDone) {
            tg.MainButton.show();
            tg.MainButton.setText(
                `Оплатити`
            )
            tg.onEvent('mainButtonClicked', goPay);
            return () => {
                tg.offEvent('mainButtonClicked', goPay);
            }
        } else {
            tg.MainButton.hide()
        }
    }, [addedItems, tg.MainButton, modalIsOpen, orderIsDone, phone, goToCart, goPay, goToForm, pizzaClicked]);

    return (
        <div className={'list-wrapper'}>
            <div key={catToRender.id} className={'list'}>
                <h1 className={'category'} id={catToRender.cat_name}>
                    {catToRender.cat_name}
                </h1>
                {Object.keys(catToRender).length ? catToRender.products.map((item) => {
                    return (
                        <ProductItem
                            product={item}
                            onAdd={onAdd}
                            onRemove={onRemove}
                            addedItems={addedItems}
                            className={'item'}
                            key={item.id}
                            handlePizzaClick={handlePizzaClick}
                        />
                )}) : ""}
            </div>

            {addedItems.length > 0 && modalIsOpen && !orderIsDone && !pizzaClicked ? <Cart
                addedItems={addedItems}
                onAdd={onAdd}
                onRemove={onRemove}
                closeModal={closeModal}
                handlePizzaClick={handlePizzaClick}
            /> : ""}

            {addedItems.length > 0 && modalIsOpen && orderIsDone && !pizzaClicked ? <Form
                addedItems={addedItems}
                phone={phone}
                setPhone={setPhone}
                street={street}
                setStreet={setStreet}
                building={building}
                setBuilding={setBuilding}
            /> : ""}

            {pizzaClicked ? <PizzaModal
                products={products}
                addedItems={addedItems}
                handlePizzaClick={handlePizzaClick}
                productForPizzaModal={productForPizzaModal}
                onAdd={onAdd}
                onRemove={onRemove}
            /> : ""}

        </div>
    );
};

export default ProductList;
