import React, {useEffect, useState} from 'react';
import Button from "../../Button/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AdditiveItem = ({addedItems, product, onAdd, onRemove}) => {

    const [additivesCounter, setAdditivesCounter] = useState(0);

    useEffect(() => {
        if (addedItems) {
            if (addedItems.length > 0 && addedItems.some((item) => item.name === product.name)) {
                addedItems.forEach(e => {
                    if (product.name === e.name) {
                        setAdditivesCounter(e.ordered);
                    }
                })
            } else {
                setAdditivesCounter(0)
            }
        }
    }, [addedItems, additivesCounter, product])


    return (
        <div className={'additive-wrapper'}>
            <div className={'cart-product'}>
                <div className={'cart-img'}>
                    <LazyLoadImage style={{width: 94}} src={product.foto_link} alt={product.name}/>
                </div>
                <div className={'title'}>{product.name}
                    <br/>
                    <div className={'description'}>{product.description = "-" ? "" : product.description}</div>
                </div>

                <div className={'price'}>
                    <span><b className={'price-tag'}>{product.price} <br/> грн.</b></span>
                </div>
                <div className={'button-container'}>
                    <h1 className={'count'}>{additivesCounter} шт.</h1>
                    <Button title={'+'} disable={false} onClick={() => onAdd(product)} type={'add'}/>
                    <Button title={'-'} disable={false} onClick={() => onRemove(product)} type={'remove'}/>
                </div>
            </div>
        </div>
    );
};

export default AdditiveItem;
