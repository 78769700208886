import React, {useEffect, useState} from 'react';
import './PizzaModal.css';
import Button from "../Button/Button";
import AdditiveItem from "./AdditiveItem/AdditiveItem";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PizzaModal = ({products, addedItems, handlePizzaClick, productForPizzaModal, onAdd, onRemove}) => {

    const [pizzaCounter, setPizzaCounter] = useState(0);
    const [additives, setAdditives] = useState([]);
    useEffect(() => {
        if (products) {
            const result = products.filter(item => item.cat_name === "Додатки до піци");
            setAdditives(result);
        }

        if (addedItems) {
            if (addedItems.length > 0 && addedItems.some((item) => item.name === productForPizzaModal.name)) {
                addedItems.forEach(e => {
                    if (productForPizzaModal.name === e.name) {
                        setPizzaCounter(e.ordered);
                    }
                })
            } else {
                setPizzaCounter(0)
            }
        }
    }, [addedItems, productForPizzaModal.name, setPizzaCounter, products]);

    return (
        <div className={'pizza-modal__wrapper'}>
            <a className={'pizza-modal__close-btn'} onClick={() => handlePizzaClick(undefined)}>️</a>
            <div className={'modal__pizza-pizza'}>
                <div className={'pizza-modal__img'}>
                    <LazyLoadImage src={productForPizzaModal.foto_link}
                         alt={productForPizzaModal.name}
                         onClick={() => handlePizzaClick(productForPizzaModal)}
                         style={{width: 150}}
                    />
                    <span className={`${
                        pizzaCounter > 0 ? "pizza-modal__count-badge" : "count-badge-hidden"
                    }`}>{pizzaCounter}</span>
                </div>
                <h1 className={'pizza-modal__title'}>{productForPizzaModal.name}</h1>
                <div className={'pizza-modal__description-price-container'}>
                    <div className={'pizza-modal__description'}>{productForPizzaModal.description}</div>
                    <div className={'pizza-modal__price-tag'}>
                        <b className={'pizza-modal__price'}>{productForPizzaModal.price} грн.</b>
                        {pizzaCounter > 0
                            ? (
                                <div className={'pizza-modal__button-container'}>
                                    <Button title={'+'} disable={false} onClick={() => onAdd(productForPizzaModal)}
                                            type={'add'}/>
                                    <Button title={'-'} disable={false} onClick={() => onRemove(productForPizzaModal)}
                                            type={'remove'}/>
                                </div>
                            )
                            : (
                                <div className={'pizza-modal__button-container'}>
                                    <Button title={'Додати'} disable={false} onClick={() => onAdd(productForPizzaModal)}
                                            type={'add'}/>
                                </div>
                            )}
                    </div>
                </div>
            </div>

            {pizzaCounter ? <h1 className={'additives-title'}>Додатки до піци: </h1> : ""}
            {additives.length > 0 && pizzaCounter > 0 ? additives[0].products.map(additive => {
                    return <AdditiveItem
                        className={'additive-item'}
                        key={additive.id}
                        addedItems={addedItems}
                        product={additive}
                        onAdd={onAdd}
                        onRemove={onRemove}
                    />;
                })
                : ""}
        </div>
    );
};

export default PizzaModal;
